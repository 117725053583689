<script setup>
const props = defineProps({
  cell: {
    default: {}
  },
  row: {
    default: {}
  },
  classButton: {
    type: String
  }
})

const emits = defineEmits(['showPointOnMap']);


const convertToClickableLinks = (text) => {
  if (typeof text !== 'string') {
    return text
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, (url) => {
    return `<a class='table-link font-bold'
               href="${url}" onclick="event.stopPropagation();"
               target="_blank"
               rel="noopener noreferrer">
                ${url}
            </a>`;
  })
}

const convertToClickableButton = (text) => {
  if(typeof text.indexOf != 'function') {
    return convertToClickableLinks(text);
  };

  let statusRow = '-';
  if(text?.indexOf('+') !== -1) {
    statusRow = '+'
  }

  return `<button name="1" class='clickable-table-row-button `+ props.classButton +`' >
              ${statusRow}
          </button> <span>${text}</span>`;
}

const showPointOnMap = (row) => {
  emits('showPointOnMap', row);
}
</script>

<template>
  <span v-if="props.cell.key === '№' && !props.row._is_total">
    <span
      class="font-semibold whitespace-no-wrap table-text"
      :class="props.row._is_total ? 'font-bold' : ''"
      v-html="convertToClickableButton(props.row[props.cell.key])"
      >
    </span>
  </span>
  <span v-else>
    <span
      v-if="props.cell.key !== 'point_name'"
      class="font-semibold whitespace-no-wrap table-text"
      :class="props.row._is_total ? 'font-bold' : ''"
      v-html="convertToClickableLinks(props.row[props.cell.key])"
      >
    </span>
    <el-tooltip
      v-else
      :open-delay="1000"
      :content="$t('reports.map_note')"
      :offset="2"
      :visible-arrow="false"
      effect="dark"
      placement="bottom"
    >
    <span class="race-point cursor-zoom-in">
      {{ props.row[props.cell.key] }}
    </span>
    </el-tooltip>
  </span>
</template>

<style lang="scss">
.clickable-table-row-button {
  position: absolute;
  display: flex;
  width: 13px;
  height: 13px;
  margin-left: -6px;
  background: #fff;
  color: #446C9D;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    background: #D1E6FF;
  }

  &:focus {
    outline: none;
  }
}
</style>
